import React, { useState, useEffect } from 'react';

import { Modal, Form, Input, Button, Switch, Cascader, Select, Spin, message, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

export default function StationZoneEditModal(props) {
  const {
    stationId,
    zoneId,
    zone = {
      isEnabled: true,
    },
    visible,
    onUpdate,
    onOk,
    onCancel,
  } = props;

  const [loading, setLoading] = useState(false);

  const [zoneHasChanged, setZoneHasChanged] = useState({});

  const onFinish = async (values) => {
    const zoneObj = {
      name: values.name,
      isEnabled: values.isEnabled || false,
    };

    // edit
    if (zoneId) {
      onUpdate({
        name: 'ZONE',
        action: 'UPDATE',
        id: zoneId,
        data: zoneObj,
      });
    } else {
      const tempId = `#${Math.random().toString().slice(2)}`;

      onUpdate({
        name: 'ZONE',
        action: 'CREATE',
        id: tempId,
        data: zoneObj,
      });
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields) {
      setZoneHasChanged({
        ...zoneHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  return (
    <Modal
      title={zoneId ? `Edit Zone (${zoneId.substr(0, 8)})` : 'Add Zone'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={zone}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Enabled"
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {zoneId ? 'Update' : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};