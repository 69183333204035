import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { Tabs, Button, Switch, Spin, Form, Input, Cascader, Typography, Divider, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import SpinFull from '../../../components/SpinFull';

const { TabPane } = Tabs;
const { Title } = Typography;
const { TextArea } = Input;

const formLayout = {
  labelCol: {
    md: { span: 8 },
    sm: { span: 14 },
  },
  wrapperCol: {
    md: { span: 14 },
  },
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 8, span: 14 },
  },
};

export default function StationSettingTab(props) {
  const history = useHistory();
  const {
    station,
    stationSettings,
    stationDevices,
    onUpdate,
    onComplete,
  } = props;
  const stationSettingConfigsData = useSelector(state => state.stationSettingConfigs);

  const [stationSettingsHasChanged, setStationSettingsHasChanged] = useState({});
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    if (stationSettings) {
      form.setFieldsValue(stationSettings);
    }
  }, [stationSettings]);

  const stationSettingsFormatted = {};
  if (stationSettings) {
    for (let key in stationSettings) {
      const stationSettingConfig = stationSettingConfigsData.find(item => item.key === key);

      if (stationSettingConfig) {
        switch (stationSettingConfig.type) {
          case 'option':
          case 'deviceId': {
            stationSettingsFormatted[key] = stationSettings[key] ? [stationSettings[key]] : null;
            break;
          }
          default:
            stationSettingsFormatted[key] = stationSettings[key];
        }
      } else {
        stationSettingsFormatted[key] = stationSettings[key];
      }
    }
  }

  const onFinish = async (values) => {
    const stationSettingsFormatted = {};
    for (let key in stationSettingsHasChanged) {
      const stationSettingConfig = stationSettingConfigsData.find(item => item.key === key);

      if (stationSettingConfig) {
        switch (stationSettingConfig.type) {
          case 'option':
          case 'deviceId': {
            stationSettingsFormatted[key] = values[key] && values[key][0] !== undefined ? values[key][0] : null;
            break;
          }
          case 'number':
            stationSettingsFormatted[key] = parseFloat(values[key]);
            break;
          case 'boolean':
            stationSettingsFormatted[key] = !!values[key];
            break;
          default:
            stationSettingsFormatted[key] = values[key];
        }
      } else {
        stationSettingsFormatted[key] = values[key];
      }
    }

    // update change records
    for (let key in stationSettingsFormatted) {
      onUpdate({
        name: 'STATION_SETTING',
        action: 'UPDATE',
        key,
        value: stationSettingsFormatted[key],
      });
    }

    setStationSettingsHasChanged({});
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields.length) {
      setStationSettingsHasChanged({
        ...stationSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    const stationDeviceOptions = stationDevices.map(stationDevice => {
      return {
        key: stationDevice.id,
        value: stationDevice.id,
        label: `${stationDevice.name} (${stationDevice.id.substr(0, 8)})${!stationDevice.isEnabled ? ` (DISABLED)` : ''}`,
      };
    });

    return stationSettingConfigsData.map((configItem, i) => {
        let valuePropName = 'value';
        switch (configItem.type) {
          case 'boolean': {
            valuePropName = 'checked';
            break;
          }
        }

        const renderInput = () => {
          switch (configItem.type) {
            case 'divider': {
              return (
                <Divider />
              );
            }
            case 'number': {
              return (
                <Input
                  type="number"
                  className={stationSettingsHasChanged[configItem.key] && 'input-has-changed'}
                />
              );
            }
            case 'string': {
              return (
                <Input
                  className={stationSettingsHasChanged[configItem.key] && 'input-has-changed'}
                />
              );
            }
            case 'option': {
              return (
                <Cascader
                  options={configItem.options.map((option) => {
                    return {
                      key: option.value,
                      value: option.value,
                      label: option.title,
                    };
                  })}
                  className={stationSettingsHasChanged[configItem.key] && 'input-has-changed'}
                  placeholder="Not selected"
                />
              );
            }
            case 'boolean': {
              return (
                <Switch
                  className={stationSettingsHasChanged[configItem.key] && 'input-has-changed'}
                />
              );
            }
            case 'deviceId': {
              return (
                <Cascader
                  options={stationDeviceOptions}
                  className={stationSettingsHasChanged[configItem.key] && 'input-has-changed'}
                  placeholder="Not selected"
                />
              );
            }
            default:
              return (
                <span>(${configItem.key})</span>
              );
          }
        };

        return (
          <Form.Item
            {...formLayout}
            key={configItem.key}
            label={configItem.title}
            name={configItem.key}
            valuePropName={valuePropName}
            rules={[
              ...(configItem.isRequired ? [{
                required: true,
                message: `Please select or enter ${configItem.title}`,
              }] : []),
            ]}
          >
            {renderInput()}
          </Form.Item>
        );
    });
  };

  return (
    <TabPane {...props}>
      {station && stationSettings && stationDevices ? (
        <Spin spinning={loading}>
          <Form
            {...formLayout}
            form={form}
            initialValues={stationSettingsFormatted}
            onFinish={onFinish}
            onFieldsChange={onFieldsChange}
          >
            {renderSettings()}

            <Form.Item {...formTailLayout}>
              <Button type="primary" htmlType="submit" block>
                Update
              </Button>
            </Form.Item>
          </Form>
        </Spin>
      ) : <SpinFull />}
    </TabPane>
  );
};