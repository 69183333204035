import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { Tabs, Card, Row, Col, Button, Empty, Tooltip, Image } from 'antd';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

const { TabPane } = Tabs;

export default function StationDashboardOverviewCamera(props) {
  const {
    station,
    zones,
  } = props;

  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [cameraFiles, setCameraFiles] = useState([]);

  useEffect(() => {
    (async () => {
      const cameraFilesNew = [];

      for (let zone of zones) {
        for (let zoneIo of zone.ios) {
          if (zoneIo.config.inputs && zoneIo.config.inputs.indexOf('IMAGE_INPUT') > -1) {

            const fileRes = await ToolRequest.request('GET', '/v1/station-file', {
              stationId: station.id,
              zoneIoId: zoneIo.id,
              count: 1,
            });

            if (fileRes.data.length) {
              cameraFilesNew.push({
                file: fileRes.data.length ? fileRes.data[0] : null,
                zoneIo,
              });
            }
          }
        }
      }

      setCameraFiles(cameraFilesNew);
      setLoaded(true);
    })();
  }, []);

  return (
    <Card
      id="cameraContainer"
      style={{height: 274}}
    >
      {loaded ? (
        cameraFiles.some(cameraFile => cameraFile.file) ? (
          <Tabs
            type="card"
            defaultActiveKey="0"
            size="small"
          >
            {cameraFiles.map((cameraFile, i) => {
              return cameraFile.file ? (
                <TabPane tab={cameraFile.zoneIo.name} key={i}>
                  <Image
                    src={cameraFile.file.url}
                  />
                  <div>
                    <Tooltip title={moment(cameraFile.file.capturedAt).format('YYYY-MM-DD HH:mm:ss')}>
                      Captured {moment(cameraFile.file.capturedAt).fromNow()}
                    </Tooltip>
                  </div>
                </TabPane>
              ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No images" />;
            })}
          </Tabs>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No images" />
      ) : <SpinFull />}
    </Card>
  );
};
