import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Space, Select, Tabs, Empty, Row, Col, Divider, Card, Spin, Button } from 'antd';
import { ReloadOutlined, BulbFilled, FilterFilled, ExperimentFilled, CameraFilled, FundViewOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import StationDashboardOutputCard from '../StationDashboardOutput/StationDashboardOutputCard';
import StationDashboardAutomationEditModal from './StationDashboardAutomationEditModal';
import StationDashboardAutomationProfilingModal from './StationDashboardAutomationProfilingModal';
import SpinFull from '../../../components/SpinFull';

const { TabPane } = Tabs;
const { Option } = Select;

export default function StationDashboardAutomationTab(props) {
  const {
    station,
    zones,
    reloadingStationZoneStatuses,
    reloadingStationZoneStatusesCountdown,
    reloadStationZoneStatuses,
  } = props;

  const [editModal, setEditModal] = useState(null);
  const [profilingModal, setProfilingModal] = useState(null);

  return (
    <TabPane {...props}>
      <Button
        type="default"
        icon={<FundViewOutlined />}
        onClick={() => {
          setProfilingModal({
            key: Math.random().toString(),
            visible: true,
          });
        }}
      >
        Automation Profiling
      </Button>
      <Spin spinning={reloadingStationZoneStatuses}>
        {zones.map(zone => (
          <Card
            key={zone.id}
            className="card-container"
            style={{marginTop: 16}}
            title={
              <>
                <Button
                  type="link"
                  size="small"
                  icon={<ReloadOutlined />}
                  style={{float: 'right'}}
                  onClick={() => reloadStationZoneStatuses()}
                  disabled={reloadingStationZoneStatuses}
                >
                  <span>{reloadingStationZoneStatusesCountdown}</span>
                </Button>

                <span>{zone.name}</span>
              </>
            }
          >
            <Divider>Automations</Divider>
            <Row gutter={8}>
              {zone.automations.map(zoneAutomation => {

                let className;

                switch (zoneAutomation.type) {
                  case 'MAIN_WATER_PUMP_BASIC': {
                    className = 'home-automation-card-main-water-pump-basic';
                    break;
                  }
                  case 'NUTRITION_PUMP_BASIC': {
                    className = 'home-automation-card-nutrition-pump-basic';
                    break;
                  }
                  case 'LED_BASIC': {
                    className = 'home-automation-card-led-basic';
                    break;
                  }
                  case 'CAMERA_BASIC': {
                    className = 'home-automation-card-camera-basic';
                    break;
                  }
                  case 'FAN_BASIC': {
                    className = 'home-automation-card-fan-basic';
                    break;
                  }
                  case 'INLET_WATER_BASIC': {
                    className = 'home-automation-card-inlet-valve-basic';
                    break;
                  }
                  case 'CO2_BASIC': {
                    className = 'home-automation-card-co2-basic';
                    break;
                  }
                }

                return (
                  <Col key={zoneAutomation.id} xs={24} md={6}>
                    <StationDashboardOutputCard
                      className={className}
                      value={zoneAutomation.isEnabled}
                      text={zoneAutomation.name}
                      onClick={() => {
                        setEditModal({
                          key: Math.random().toString(),
                          zoneAutomationId: zoneAutomation.id,
                          visible: true,
                        });
                      }}
                    />
                  </Col>
                );
              })}
            </Row>
          </Card>
        ))}

        {editModal && (
          <StationDashboardAutomationEditModal
            key={editModal.key}
            zoneAutomationId={editModal.zoneAutomationId}
            visible={editModal.visible}
            onOk={() => {
              setEditModal({
                ...editModal,
                visible: false,
              });

              reloadStationZoneStatuses();
            }}
            onCancel={() => {
              setEditModal({
                ...editModal,
                visible: false,
              });
            }}
          />
        )}

        {profilingModal && (
          <StationDashboardAutomationProfilingModal
            key={profilingModal.key}
            station={station}
            zones={zones}
            visible={profilingModal.visible}
            onCancel={() => {
              setProfilingModal({
                ...profilingModal,
                visible: false,
              });
            }}
          />
        )}
      </Spin>
    </TabPane>
  );
};