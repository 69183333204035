import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Space, Select, Tabs, Empty, Row, Col, Divider, Card, Spin, Button } from 'antd';
import { ReloadOutlined, BulbFilled, FilterFilled, ExperimentFilled, StockOutlined, SlackOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import StationDashboardOutputCard from './StationDashboardOutputCard';
import StationDashboardOutputEditModal from './StationDashboardOutputEditModal';
import SpinFull from '../../../components/SpinFull';

const { TabPane } = Tabs;
const { Option } = Select;

export default function StationDashboardOutputTab(props) {
  const {
    station,
    zones,
    zoneStatuses,
    reloadingStationZoneStatuses,
    reloadingStationZoneStatusesCountdown,
    reloadStationZoneStatuses,
  } = props;

  const [editModal, setEditModal] = useState(null);

  return (
    <TabPane {...props}>
      {zoneStatuses ? (
        <Spin spinning={reloadingStationZoneStatuses}>
          {zones.map(zone => (
            <Card
              key={zone.id}
              className="card-container"
              style={{marginTop: 16}}
              title={
                <>
                  <Button
                    type="link"
                    size="small"
                    icon={<ReloadOutlined />}
                    style={{float: 'right'}}
                    onClick={() => reloadStationZoneStatuses()}
                    disabled={reloadingStationZoneStatuses}
                  >
                    <span>{reloadingStationZoneStatusesCountdown}</span>
                  </Button>

                  <span>{zone.name}</span>
                </>
              }
            >
              <Divider>Controls</Divider>
              <Row gutter={8}>
                {zone.ios.filter(zoneIo => zoneIo.config.outputs).map(zoneIo => {
                  let latestValue = zoneStatuses[zone.id][`latestOutputValue[${zoneIo.id}]`];

                  if (latestValue === undefined) {
                    latestValue = null;
                  }

                  let latestOutputValueUpdatedAt = zoneStatuses[zone.id][`latestOutputValueUpdatedAt[${zoneIo.id}]`];

                  let className;

                  switch (zoneIo.type) {
                    case 'LED': {
                      className = 'home-control-card-led';
                      break;
                    }
                    case 'MAIN_WATER_PUMP': {
                      className = 'home-control-card-main-water-pump';
                      break;
                    }
                    case 'NUTRITION_PUMP': {
                      className = 'home-control-card-nutrition-pump';
                      break;
                    }
                    case 'INLET_VALVE': {
                      className = 'home-control-card-inlet-valve';
                      break;
                    }
                    case 'FAN': {
                      className = 'home-control-card-fan';
                      break;
                    }
                    case 'CO2_VALVE': {
                      className = 'home-control-card-co2-valve';
                      break;
                    }
                  }

                  return (
                    <Col key={zoneIo.id} xs={24} md={6}>
                      <StationDashboardOutputCard
                        className={className}
                        value={latestValue}
                        text={zoneIo.name}
                        onClick={() => {
                          setEditModal({
                            key: Math.random().toString(),
                            zoneIoId: zoneIo.id,
                            visible: true,
                          });
                        }}
                      />
                    </Col>
                  );
                })}
              </Row>
            </Card>
          ))}

          {editModal && (
            <StationDashboardOutputEditModal
              key={editModal.key}
              zoneIoId={editModal.zoneIoId}
              visible={editModal.visible}
              onOk={() => {
                setEditModal({
                  ...editModal,
                  visible: false,
                });

                reloadStationZoneStatuses();
              }}
              onCancel={() => {
                setEditModal({
                  ...editModal,
                  visible: false,
                });
              }}
            />
          )}
        </Spin>
      ) : <SpinFull />}
    </TabPane>
  );
};