import React, { useState, useEffect } from 'react';

import { Modal, Form, Input, Button, Spin, Select, message, Divider } from 'antd'

import * as ToolRequest from '../../tools/ToolRequest';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

export default function ChangePasswordModal(props) {
  const { visible, onOk, onCancel } = props;

  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      await ToolRequest.request('POST', `/v1/auth/password/change`, {
        password: values.password,
        passwordNew: values.passwordNew,
      });

      message.success('Password changed successfully');

      onOk();
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          case 'INCORRECT_PASSWORD': {
            return message.error('Incorrect password');
          }
          default:
            return message.error('Unknown errors. Please retry');
        }
      }
      message.error('Unknown errors. Please retry');
    }
  };

  return (
    <Modal
      title="Change Password"
      visible={visible}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          onFinish={onFinish}
        >
          <Form.Item
            name="password"
            label="Current Password"
            rules={[
              {
                required: true,
                message: 'Please enter Current Password',
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="Current Password"
            />
          </Form.Item>
          <Divider />
          <Form.Item
            name="passwordNew"
            label="New Password"
            rules={[
              {
                required: true,
                message: 'Please enter New Password',
              },
            ]}
            hasFeedback
          >
            <Input.Password
              placeholder="New Password"
            />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="New Password Again"
            dependencies={['passwordNew']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please enter New Password Again',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('passwordNew') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(new Error('Password does not match'));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="New Password Again"
            />
          </Form.Item>
          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Update
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};