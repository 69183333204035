import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader, Tabs, Button, Spin, Alert, Space, message, Form } from 'antd';
import { SettingOutlined, ApiOutlined, LeftOutlined, CameraOutlined, InsertRowAboveOutlined, BranchesOutlined, RobotOutlined, UnorderedListOutlined, SaveOutlined, CloudUploadOutlined, CloudDownloadOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import StationSettingTab from './StationSetting/StationSettingTab';
import StationSettingDeviceTab from './StationSettingDevice/StationSettingDeviceTab';
import StationZoneTab from './StationZone/StationZoneTab';
import StationZoneIoTab from './StationZoneIo/StationZoneIoTab';
import StationZoneAutomationTab from './StationZoneAutomation/StationZoneAutomationTab';
import StationSettingChangeModal from './StationSettingChangeModal';
import StationSettingExportModal from './StationSettingExportModal';
import StationSettingImportModal from './StationSettingImportModal';

import '../../less/StationDashboardScreen.less';

export default function StationSettingScreen(props) {
  const history = useHistory();
  const { stationId } = useParams();

  const [componentKey, setComponentKey] = useState(Math.random().toString());

  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('setting');

  const [station, setStation] = useState(null);
  const [stationSettings, setStationSettings] = useState(null);
  const [stationDevices, setStationDevices] = useState(null);
  const [zones, setZones] = useState(null);

  const [changes, setChanges] = useState([]);
  const [changeModal, setChangeModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });
  const [exportModal, setExportModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });
  const [importModal, setImportModal] = useState({
    key: Math.random().toString(),
    visible: false,
  });
  const [savedChangeCount, setSavedChangeCount] = useState(0);

  useEffect(() => {
    load();
  }, []);

  const load = async (startingSequenceName) => {
    const startingSequence = {
      'STATION_SETTING': 0,
      'DEVICE': 1,
      'ZONE': 2,
      'ZONE_IO': 3,
      'ZONE_AUTOMATION': 4,
    }[startingSequenceName] || 0;

    setLoading(true);

    // station
    const stationRes = await ToolRequest.request('GET', `/v1/station/${stationId}`);
    setStation(stationRes);

    // station setting
    if (startingSequence <= 0) {
      const stationSettingRes = await ToolRequest.request('GET', `/v1/station/${stationId}/setting`);
      setStationSettings(stationSettingRes);
    }

    // station devices
    if (startingSequence <= 1) {
      const stationDevicesNew = [];

      const stationDeviceRes = await ToolRequest.request('GET', '/v1/station-device', {
        stationId,
        count: 1000,
      });

      for (let stationDevice of stationDeviceRes.data) {
        // station device settings
        const stationDeviceSettingRes = await ToolRequest.request('GET', `/v1/station-device/${stationDevice.id}/setting`);

        stationDevicesNew.push({
          ...stationDevice,
          settings: stationDeviceSettingRes,
        });
      }

      setStationDevices(stationDevicesNew);
    }

    // zones
    const zonesNew = [];

    let stationZonesNew = zones || [];
    if (startingSequence <= 2) {
      const stationZoneRes = await ToolRequest.request('GET', '/v1/station-zone', {
        stationId,
        count: 1000,
      });

      stationZonesNew = stationZoneRes.data;
    }

    for (let stationZone of stationZonesNew) {
      const ios = [];

      // zone ios
      let stationZoneIosNew = stationZone.ios || [];
      if (startingSequence <= 3) {
        const stationZoneIoRes = await ToolRequest.request('GET', '/v1/station-zone-io', {
          zoneId: stationZone.id,
          count: 10000,
        });

        stationZoneIosNew = stationZoneIoRes.data;
      }

      for (let zoneIo of stationZoneIosNew) {
        // zone io settings
        let zoneIoSettings = zoneIo.settings || [];

        if (startingSequence <= 3) {
          const stationZoneIoSettingRes = await ToolRequest.request('GET', `/v1/station-zone-io/${zoneIo.id}/setting`);

          zoneIoSettings = stationZoneIoSettingRes;
        }

        // zone device
        let ioDevices = zoneIo.ioDevices || [];

        if (startingSequence <= 3) {
          const stationZoneIoDeviceRes = await ToolRequest.request('GET', `/v1/station-zone-io/${zoneIo.id}/device`);

          ioDevices = stationZoneIoDeviceRes.data;
        }

        const zoneIoDeviceNew = [];

        for (let zoneIoDevice of ioDevices) {
          let zoneIoDeviceSettings = zoneIoDevice.settings || [];

          if (startingSequence <= 3) {
            const stationZoneIoDeviceSettingRes = await ToolRequest.request('GET', `/v1/station-zone-io/${zoneIo.id}/device/${zoneIoDevice.id}/setting`);

            zoneIoDeviceSettings = stationZoneIoDeviceSettingRes;
          }

          zoneIoDeviceNew.push({
            ...zoneIoDevice,
            settings: zoneIoDeviceSettings,
          });
        }

        ios.push({
          ...zoneIo,
          ioDevices: zoneIoDeviceNew,
          settings: zoneIoSettings,
        });
      }

      // zone automation
      const automations = [];

      const stationZoneAutomationRes = await ToolRequest.request('GET', `/v1/station-zone-automation`, {
        zoneId: stationZone.id,
        count: 1000,
      });

      for (let zoneAutomation of stationZoneAutomationRes.data) {
        const stationZoneAutomationSettingRes = await ToolRequest.request('GET', `/v1/station-zone-automation/${zoneAutomation.id}/setting`);

        automations.push({
          ...zoneAutomation,
          settings: stationZoneAutomationSettingRes,
        });
      }

      zonesNew.push({
        ...stationZone,
        ios,
        automations,
      });
    }

    setZones(zonesNew);

    setLoading(false);
  };

  const onUpdate = (record) => {
    const {
      name,       // STATION_SETTING, DEVICE, DEVICE_SETTING, ZONE, ZONE_IO, ZONE_IO_SETTING, ZONE_IO_DEVICE, ZONE_IO_DEVICE_SETTING, ZONE_AUTOMATION, ZONE_AUTOMATION_SETTING
      action,     // CREATE, UPDATE, REMOVE
      id,
      key,
      value,
      data,
    } = record;

    // add change records
    const extra = {};

    if (action === 'REMOVE') {
      switch (name) {
        case 'DEVICE': {
          extra.name = stationDevices.find(stationDevice => stationDevice.id === id).name;
          break;
        }
        case 'ZONE': {
          extra.name = zones.find(zone => zone.id === id).name;
          break;
        }
        case 'ZONE_IO': {
          extra.name = zones.find(zone => zone.ios.some(io => io.id === id)).ios.find(io => io.id === id).name;
          break;
        }
        case 'ZONE_IO_DEVICE': {
          extra.ioName = zones.find(zone => zone.ios.some(io => io.ioDevices.some(ioDevice => ioDevice.id === id))).ios.find(io => io.ioDevices.some(ioDevice => ioDevice.id === id)).name;
          break;
        }
        case 'ZONE_AUTOMATION': {
          extra.name = zones.find(zone => zone.automations.some(automation => automation.id === id)).automations.find(automation => automation.id === id).name;
          break;
        }
      }
    }

    setChanges(changes => [
      ...changes,
      {
        ...record,
        extra,
      },
    ]);

    // update loaded data
    switch (name) {
      case 'STATION_SETTING': {
        setStationSettings(stationSettings => ({
          ...stationSettings,
          [key]: value,
        }));
        break;
      }
      case 'DEVICE': {
        switch (action) {
          case 'CREATE': {
            setStationDevices(stationDevices => [
              ...stationDevices,
              {
                ...data,
                id,
              },
            ]);
            break;
          }
          case 'UPDATE': {
            setStationDevices(stationDevices => stationDevices.map(stationDevice => {
              return stationDevice.id === id ? {
                ...stationDevice,
                ...data,
              } : stationDevice;
            }));
            break;
          }
          case 'REMOVE': {
            setStationDevices(stationDevices => stationDevices.filter(stationDevice => stationDevice.id !== id));
            break;
          }
        }
        break;
      }
      case 'DEVICE_SETTING': {
        setStationDevices(stationDevices => stationDevices.map(stationDevice => {
          return stationDevice.id === id ? {
            ...stationDevice,
            settings: {
              ...stationDevice.settings,
              [key]: value,
            },
          } : stationDevice;
        }));
        break;
      }
      case 'ZONE': {
        switch (action) {
          case 'CREATE': {
            setZones(zones => [
              ...zones,
              {
                ...data,
                id,
                ios: [],
                automations: [],
              },
            ]);
            break;
          }
          case 'UPDATE': {
            setZones(zones => zones.map(zone => {
              return zone.id === id ? {
                ...zone,
                ...data,
              } : zone;
            }));
            break;
          }
          case 'REMOVE': {
            setZones(zones => zones.filter(zone => zone.id !== id));
            break;
          }
        }
        break;
      }
      case 'ZONE_IO': {
        switch (action) {
          case 'CREATE': {
            setZones(zones => zones.map(zone => {
              return zone.id === data.zoneId ? {
                ...zone,
                ios: [
                  ...zone.ios,
                  {
                    ...data,
                    id,
                    ioDevices: [],
                    settings: [],
                  },
                ],
              } : zone;
            }));
            break;
          }
          case 'UPDATE': {
            setZones(zones => zones.map(zone => {
              return zone.ios.some(io => io.id === id) ? {
                ...zone,
                ios: zone.ios.map(io => io.id === id ? {
                  ...io,
                  ...data,
                } : io),
              } : zone;
            }));
            break;
          }
          case 'REMOVE': {
            setZones(zones => zones.map(zone => {
              return zone.ios.some(io => io.id === id) ? {
                ...zone,
                ios: zone.ios.filter(io => io.id !== id),
              } : zone;
            }));
            break;
          }
        }
        break;
      }
      case 'ZONE_IO_SETTING': {
        setZones(zones => zones.map(zone => {
          return zone.ios.some(io => io.id === id) ? {
            ...zone,
            ios: zone.ios.map(io => io.id === id ? {
              ...io,
              settings: {
                ...io.settings,
                [key]: value,
              },
            } : io),
          } : zone;
        }));
        break;
      }
      case 'ZONE_IO_DEVICE': {
        switch (action) {
          case 'CREATE': {
            setZones(zones => zones.map(zone => {
              return zone.ios.some(io => io.id === data.zoneIoId) ? {
                ...zone,
                ios: zone.ios.map(io => io.id === data.zoneIoId ? {
                  ...io,
                  ioDevices: [
                    ...io.ioDevices,
                    {
                      ...data,
                      id,
                      settings: [],
                    },
                  ],
                } : io),
              } : zone;
            }));
            break;
          }
          case 'UPDATE': {
            setZones(zones => zones.map(zone => {
              return zone.ios.some(io => io.ioDevices.some(ioDevice => ioDevice.id === id)) ? {
                ...zone,
                ios: zone.ios.map(io => io.ioDevices.some(ioDevice => ioDevice.id === id) ? {
                  ...io,
                  ioDevices: io.ioDevices.map(ioDevice => ioDevice.id === id ? {
                    ...ioDevice,
                    ...data,
                  } : ioDevice),
                } : io),
              } : zone;
            }));
            break;
          }
          case 'REMOVE': {
            setZones(zones => zones.map(zone => {
              return zone.ios.some(io => io.ioDevices.some(ioDevice => ioDevice.id === id)) ? {
                ...zone,
                ios: zone.ios.map(io => io.ioDevices.some(ioDevice => ioDevice.id === id) ? {
                  ...io,
                  ioDevices: io.ioDevices.filter(ioDevice => ioDevice.id !== id),
                } : io),
              } : zone;
            }));
            break;
          }
        }
        break;
      }
      case 'ZONE_IO_DEVICE_SETTING': {
        setZones(zones => zones.map(zone => {
          return zone.ios.some(io => io.ioDevices.some(ioDevice => ioDevice.id === id)) ? {
            ...zone,
            ios: zone.ios.map(io => io.ioDevices.some(ioDevice => ioDevice.id === id) ? {
              ...io,
              ioDevices: io.ioDevices.map(ioDevice => ioDevice.id === id ? {
                ...ioDevice,
                settings: {
                  ...ioDevice.settings,
                  [key]: value,
                },
              } : ioDevice),
            } : io),
          } : zone;
        }));
        break;
      }
      case 'ZONE_AUTOMATION': {
        switch (action) {
          case 'CREATE': {
            setZones(zones => zones.map(zone => {
              return zone.id === data.zoneId ? {
                ...zone,
                automations: [
                  ...zone.automations,
                  {
                    ...data,
                    id,
                    settings: [],
                  },
                ],
              } : zone;
            }));
            break;
          }
          case 'UPDATE': {
            setZones(zones => zones.map(zone => {
              return zone.automations.some(automation => automation.id === id) ? {
                ...zone,
                automations: zone.automations.map(automation => automation.id === id ? {
                  ...automation,
                  ...data,
                } : automation),
              } : zone;
            }));
            break;
          }
          case 'REMOVE': {
            setZones(zones => zones.map(zone => {
              return zone.automations.some(automation => automation.id === id) ? {
                ...zone,
                automations: zone.automations.filter(automation => automation.id !== id),
              } : zone;
            }));
            break;
          }
        }
        break;
      }
      case 'ZONE_AUTOMATION_SETTING': {
        setZones(zones => zones.map(zone => {
          return zone.automations.some(automation => automation.id === id) ? {
            ...zone,
            automations: zone.automations.map(automation => automation.id === id ? {
              ...automation,
              settings: {
                ...automation.settings,
                [key]: value,
              },
            } : automation),
          } : zone;
        }));
        break;
      }
    }
  };

  const onFinish = async () => {
    setLoading(true);
    setSavedChangeCount(0);

    const idMapping = {};

    for (let i = 0; i < changes.length; ++i) {
      const {
        name,       // STATION_SETTING, DEVICE, DEVICE_SETTING, ZONE, ZONE_IO, ZONE_IO_SETTING, ZONE_IO_DEVICE, ZONE_IO_DEVICE_SETTING, ZONE_AUTOMATION, ZONE_AUTOMATION_SETTING
        action,     // CREATE, UPDATE, REMOVE
        key,
      } = changes[i];

      let id = changes[i].id;
      if (id && id.match(/^#/) && idMapping[id]) {
        id = idMapping[id];
      }

      let value = changes[i].value;
      if (key && key.match(/Id$/) && value && value.match(/^#/) && idMapping[value]) {
        value = idMapping[value];
      }

      let data = changes[i].data;
      for (let dataKey in data) {
        if (dataKey && dataKey.match(/Id$/) && data[dataKey] && data[dataKey].match(/^#/) && idMapping[data[dataKey]]) {
          data[dataKey] = idMapping[data[dataKey]];
        }
      }

      try {
        switch (name) {
          case 'STATION_SETTING': {
            await ToolRequest.request('PUT', `/v1/station/${stationId}/setting/${key}`, {
              value,
            });
            break;
          }
          case 'DEVICE': {
            switch (action) {
              case 'CREATE': {
                const res = await ToolRequest.request('POST', `/v1/station-device`, {
                  ...data,
                  stationId,
                });

                idMapping[id] = res.id;
                break;
              }
              case 'UPDATE': {
                await ToolRequest.request('PUT', `/v1/station-device/${id}`, data);
                break;
              }
              case 'REMOVE': {
                await ToolRequest.request('DELETE', `/v1/station-device/${id}`);
                break;
              }
            }
            break;
          }
          case 'DEVICE_SETTING': {
            await ToolRequest.request('PUT', `/v1/station-device/${id}/setting/${key}`, {
              value,
            });
            break;
          }
          case 'ZONE': {
            switch (action) {
              case 'CREATE': {
                const res = await ToolRequest.request('POST', `/v1/station-zone`, {
                  ...data,
                  stationId,
                });

                idMapping[id] = res.id;
                break;
              }
              case 'UPDATE': {
                await ToolRequest.request('PUT', `/v1/station-zone/${id}`, data);
                break;
              }
              case 'REMOVE': {
                await ToolRequest.request('DELETE', `/v1/station-zone/${id}`);
                break;
              }
            }
            break;
          }
          case 'ZONE_IO': {
            switch (action) {
              case 'CREATE': {
                const res = await ToolRequest.request('POST', `/v1/station-zone-io`, {
                  ...data,
                  stationId,
                });

                idMapping[id] = res.id;
                break;
              }
              case 'UPDATE': {
                await ToolRequest.request('PUT', `/v1/station-zone-io/${id}`, data);
                break;
              }
              case 'REMOVE': {
                await ToolRequest.request('DELETE', `/v1/station-zone-io/${id}`);
                break;
              }
            }
            break;
          }
          case 'ZONE_IO_SETTING': {
            await ToolRequest.request('PUT', `/v1/station-zone-io/${id}/setting/${key}`, {
              value,
            });
            break;
          }
          case 'ZONE_IO_DEVICE': {
            switch (action) {
              case 'CREATE': {
                const res = await ToolRequest.request('POST', `/v1/station-zone-io/${data.zoneIoId}/device`, {
                  ...data,
                });

                idMapping[id] = res.id;
                break;
              }
              // case 'UPDATE': {
              //   await ToolRequest.request('PUT', `/v1/station-zone-io/${id}/device`, data);
              //   break;
              // }
              case 'REMOVE': {
                await ToolRequest.request('DELETE', `/v1/station-zone-io/${data.zoneIoId}/device/${id}`);
                break;
              }
            }
            break;
          }
          case 'ZONE_IO_DEVICE_SETTING': {
            await ToolRequest.request('PUT', `/v1/station-zone-io/${data.zoneIoId}/device/${id}/setting/${key}`, {
              value,
            });
            break;
          }
          case 'ZONE_AUTOMATION': {
            switch (action) {
              case 'CREATE': {
                const res = await ToolRequest.request('POST', `/v1/station-zone-automation`, {
                  ...data,
                  stationId,
                });

                idMapping[id] = res.id;
                break;
              }
              case 'UPDATE': {
                await ToolRequest.request('PUT', `/v1/station-zone-automation/${id}`, data);
                break;
              }
              case 'REMOVE': {
                await ToolRequest.request('DELETE', `/v1/station-zone-automation/${id}`);
                break;
              }
            }
            break;
          }
          case 'ZONE_AUTOMATION_SETTING': {
            await ToolRequest.request('PUT', `/v1/station-zone-automation/${id}/setting/${key}`, {
              value,
            });
            break;
          }
        }
      } catch (err) {
      }

      setSavedChangeCount(count => count + 1);
    }

    setChanges([]);
    setSavedChangeCount(0);
    setLoading(false);

    message.success('All changes are saved successfully');

    onComplete('STATION_SETTING');    // TODO improve this
  };

  const onComplete = async (sequenceName) => {
    await load(sequenceName);

    setComponentKey(Math.random().toString());
  };

  return (
    <PageHeader
      title={`Station Settings (${station ? station.displayName : '...'})`}
      onBack={() => history.push(history.location.pathname.replace(/^\/station\/setting/, '/station/dashboard'))}
    >
      <Spin spinning={loading}>
        <Alert
          message={changes.length ? `Unsaved changes: ${changes.length - savedChangeCount}` : 'No changes yet'}
          type={changes.length ? 'warning' : 'success'}
          showIcon
          action={
            <Space>
              <Button
                type="default"
                icon={<CloudUploadOutlined />}
                onClick={() => setImportModal({
                  key: Math.random().toString(),
                  visible: true,
                })}
              >
                Import
              </Button>
              <Button
                type="default"
                icon={<CloudDownloadOutlined />}
                onClick={() => setExportModal({
                  key: Math.random().toString(),
                  visible: true,
                })}
              >
                Export
              </Button>
              <Button
                type="default"
                icon={<UnorderedListOutlined />}
                disabled={!changes.length}
                onClick={() => setChangeModal({
                  key: Math.random().toString(),
                  visible: true,
                })}
              >
                Show
              </Button>
              <Button
                type="primary"
                icon={<SaveOutlined />}
                disabled={!changes.length}
                onClick={() => onFinish()}
              >
                Save
              </Button>
            </Space>
          }
        />
        <StationSettingChangeModal
          key={changeModal.key}
          visible={changeModal.visible}
          station={station}
          stationSettings={stationSettings}
          stationDevices={stationDevices}
          zones={zones}
          changes={changes}
          onCancel={() => setChangeModal({
            ...changeModal,
            visible: false,
          })}
        />
        <StationSettingExportModal
          key={exportModal.key}
          visible={exportModal.visible}
          stationSettings={stationSettings}
          stationDevices={stationDevices}
          zones={zones}
          onCancel={() => setExportModal({
            ...exportModal,
            visible: false,
          })}
        />
        <StationSettingImportModal
          key={importModal.key}
          visible={importModal.visible}
          onCancel={() => setImportModal({
            ...importModal,
            visible: false,
          })}
          station={station}
          stationDevices={stationDevices}
          zones={zones}
          onUpdate={onUpdate}
          onOk={() => setComponentKey(Math.random().toString())}
        />

        <Tabs
          defaultActiveKey={activeTab}
          activeKey={activeTab}
          key={componentKey}
          onChange={activeKeyNew => setActiveTab(activeKeyNew)}
        >
          <StationSettingTab
            key="setting"
            tab={
              <span>
                <SettingOutlined />
                Settings
              </span>
            }
            station={station}
            stationSettings={stationSettings}
            stationDevices={stationDevices}
            onUpdate={onUpdate}
          />
          <StationSettingDeviceTab
            key="device"
            tab={
              <span>
                <ApiOutlined />
                Devices
              </span>
            }
            station={station}
            stationDevices={stationDevices}
            onUpdate={onUpdate}
          />
          <StationZoneTab
            key="zone"
            tab={
              <span>
                <InsertRowAboveOutlined />
                Zones
              </span>
            }
            station={station}
            zones={zones}
            onUpdate={onUpdate}
          />
          <StationZoneIoTab
            key="zoneIo"
            tab={
              <span>
                <BranchesOutlined />
                Zone IOs
              </span>
            }
            station={station}
            stationDevices={stationDevices}
            zones={zones}
            onUpdate={onUpdate}
          />
          <StationZoneAutomationTab
            key="zoneAutomation"
            tab={
              <span>
                <RobotOutlined />
                Zone Automations
              </span>
            }
            station={station}
            stationDevices={stationDevices}
            zones={zones}
            onUpdate={onUpdate}
          />
        </Tabs>
      </Spin>
    </PageHeader>
  );
};
