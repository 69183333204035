import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Button, Switch, Select, Spin, message, Divider, Card, List, Avatar } from 'antd';
import { EditOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons';

import StationZoneIoDeviceEditModal from './StationZoneIoDeviceEditModal';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Option } = Select;

export default function StationZoneIoDeviceModal(props) {
  const {
    stationDevices,
    stationId,
    zoneId,
    zoneIoId,
    zoneIo = {},
    onUpdate,
    visible,
    onOk,
    onCancel,
  } = props;
  const zoneIoConfigsData = useSelector(state => state.zoneIoConfigs);
  const stationDeviceConfigsData = useSelector(state => state.stationDeviceConfigs);

  const [loading, setLoading] = useState(false);

  const [editModal, setEditModal] = useState(null);

  const zoneIoConfig = zoneIoConfigsData.find(zoneIoConfigItem => zoneIoConfigItem.type === zoneIo.type);

  const zoneIoInputOutputs = [
    ...(zoneIoConfig.inputs || []),
    ...(zoneIoConfig.outputs || []),
  ];

  const zoneIoDevices = zoneIoInputOutputs.map((inputOutput, index) => ({
    index,
    type: inputOutput,
    ioDevice: zoneIo.ioDevices.find(ioDevice => ioDevice.index === index) || null,
  }));

  return (
    <Modal
      title="Edit Zone IO Device Settings"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="60%"
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <List
          bordered
          size="large"
          dataSource={zoneIoDevices}
          renderItem={zoneIoDeviceConfig => {
            const device = zoneIoDeviceConfig.ioDevice ? stationDevices.find(stationDevice => stationDevice.id === zoneIoDeviceConfig.ioDevice.deviceId) : null;

            return (
              <List.Item
                actions={zoneIoDeviceConfig.ioDevice ? [
                  <a key="edit" onClick={() => {
                    setEditModal({
                      ...editModal,
                      key: Math.random().toString(),
                      visible: true,
                      zoneIoDeviceId: zoneIoDeviceConfig.ioDevice.id,
                      zoneIoDeviceConfig,
                    });
                  }}>Edit</a>,
                  <a key="remove" onClick={() => {
                    Modal.confirm({
                      title: 'Confirm to REMOVE zone IO device',
                      content: 'Are you sure to remove this zone IO device?',
                      onOk: () => {
                        onUpdate({
                          name: 'ZONE_IO_DEVICE',
                          action: 'REMOVE',
                          id: zoneIoDeviceConfig.ioDevice.id,
                          data: {
                            zoneIoId,
                          }
                        });
                      },
                    });
                  }}>Remove</a>,
                ] : null}
              >
                <List.Item.Meta
                  avatar={(
                    <Avatar style={zoneIoDeviceConfig.ioDevice ? { backgroundColor: '#f08412' } : {}}>
                      {zoneIoDeviceConfig.index + 1}
                    </Avatar>
                  )}
                  title={zoneIoDeviceConfig.ioDevice ? (
                    <div>{device ? device.name : '-'}</div>
                  ) : (
                    <Button
                      onClick={() => {
                        setEditModal({
                          ...editModal,
                          key: Math.random().toString(),
                          visible: true,
                          zoneIoDeviceId: null,
                          zoneIoDeviceConfig,
                        });
                      }}
                    >
                      <PlusOutlined />
                      Select Device
                    </Button>
                  )}
                  description={zoneIoDeviceConfig.ioDevice && device ? (
                    <ul>
                      {Object.keys(device.settings).map(key => (
                        <li key={key}>
                          <span style={{ fontWeight: 'bold', marginRight: 4 }}>{key}:</span>
                          <span>{device.settings[key]}</span>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                />
              </List.Item>
            );
          }}
        />

        {editModal && (
          <StationZoneIoDeviceEditModal
            key={editModal.key}
            stationDevices={stationDevices}
            stationId={stationId}
            zoneId={zoneId}
            zoneIoId={zoneIoId}
            zoneIoDeviceId={editModal.zoneIoDeviceId}
            zoneIoDevice={editModal.zoneIoDeviceId ? zoneIo.ioDevices.find(ioDeviceItem => ioDeviceItem.id === editModal.zoneIoDeviceId) : null}
            zoneIoDeviceConfig={editModal.zoneIoDeviceConfig}
            visible={!!editModal.visible}
            onUpdate={onUpdate}
            onOk={() => {
              setEditModal({
                ...editModal,
                visible: false,
              });
            }}
            onCancel={() => {
              setEditModal({
                ...editModal,
                visible: false,
              });
            }}
          />
        )}

      </Spin>
    </Modal>
  );
};