import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Input } from 'antd';

const { TextArea } = Input;

export default function StationSettingExportModal(props) {
  const {
    stationSettings,
    stationDevices,
    zones,
    visible,
    onCancel,
  } = props;

  const data = {
    stationSettings,
    stationDevices,
    zones,
  };

  return (
    <Modal
      title="Export Saved Settings"
      visible={visible}
      onCancel={onCancel}
      width="80%"
      footer={null}
    >
      <TextArea
        rows={4}
        value={JSON.stringify(data)}
      />
    </Modal>
  );
};