import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Button, Switch, Select, Spin, message, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Option } = Select;

export default function StationSettingDeviceEditModal(props) {
  const {
    stationId,
    stationDeviceId,
    stationDevice = {
      isEnabled: true,
    },
    visible,
    onUpdate,
    onOk,
    onCancel,
  } = props;
  const stationDeviceConfigsData = useSelector(state => state.stationDeviceConfigs);

  const [loading, setLoading] = useState(false);

  const [selectedModel, setSelectedModel] = useState(stationDevice ? stationDevice.model : null);
  const [stationDeviceSettingsHasChanged, setStationDeviceSettingsHasChanged] = useState({});

  // flatten settings
  const stationDeviceFlatten = (() => {
    const flatten = {
      ...stationDevice,
    };

    if (stationDevice) {
      delete flatten.settings;

      for (let key in stationDevice.settings) {
        flatten[`settings.${key}`] = stationDevice.settings[key];
      }
    }

    return flatten;
  })();

  const onFinish = async (values) => {
    const settingConfigs = stationDeviceConfigsData.find(deviceConfig => deviceConfig.model === selectedModel).settings;

    const stationDeviceObj = {
      stationId,
      name: values.name,
      model: values.model,
      isEnabled: values.isEnabled || false,
    };

    const stationDeviceSettingsObj = {};

    // device settings
    for (let settingConfig of settingConfigs) {
      if (stationDeviceSettingsHasChanged[`settings.${settingConfig.key}`]) {
        let value = values[`settings.${settingConfig.key}`];

        switch (settingConfig.type) {
          case 'option': {
            value = value !== undefined ? value : null;
            break;
          }
          case 'number':
            value = parseFloat(value);
            break;
        }

        stationDeviceSettingsObj[settingConfig.key] = value;
      }
    }

    // edit
    if (stationDeviceId) {
      onUpdate({
        name: 'DEVICE',
        action: 'UPDATE',
        id: stationDeviceId,
        data: stationDeviceObj,
      });

      for (let key in stationDeviceSettingsObj) {
        onUpdate({
          name: 'DEVICE_SETTING',
          action: 'UPDATE',
          id: stationDeviceId,
          key,
          value: stationDeviceSettingsObj[key],
        });
      }
    } else {
      const tempId = `#${Math.random().toString().slice(2)}`;

      onUpdate({
        name: 'DEVICE',
        action: 'CREATE',
        id: tempId,
        data: stationDeviceObj,
      });

      for (let key in stationDeviceSettingsObj) {
        onUpdate({
          name: 'DEVICE_SETTING',
          action: 'UPDATE',
          id: tempId,
          key,
          value: stationDeviceSettingsObj[key],
        });
      }
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields) {
      setStationDeviceSettingsHasChanged({
        ...stationDeviceSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    const settingConfigs = stationDeviceConfigsData.find(deviceConfig => deviceConfig.model === selectedModel).settings;

    return [
      settingConfigs.length ? <Divider key="divider" /> : null,
      ...settingConfigs.map((item, i) => {
        switch (item.type) {
          case 'number': {
            return (
              <Form.Item
                {...formLayout}
                key={`${selectedModel}-${item.key}`}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
              >
                <Input
                  type="number"
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  defaultValue={item.defaultValue}
                />
              </Form.Item>
            );
          }
          case 'string': {
            return (
              <Form.Item
                {...formLayout}
                key={`${selectedModel}-${item.key}`}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
              >
                <Input
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  defaultValue={item.defaultValue}
                />
              </Form.Item>
            );
          }
          case 'boolean': {
            return (
              <Form.Item
                {...formLayout}
                key={`${selectedModel}-${item.key}`}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
                valuePropName="checked"
              >
                <Switch
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  defaultValue={item.defaultValue}
                />
              </Form.Item>
            );
          }
          case 'option': {
            return (
              <Form.Item
                {...formLayout}
                key={`${selectedModel}-${item.key}`}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
              >
                <Select
                  className={stationDeviceSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  defaultValue={item.defaultValue}
                  placeholder="Not selected"
                >
                  {item.options.map((option) => {
                    return (
                      <Option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }
          default:
            return (
              <span>(${item.key})</span>
            );
        }
      }),
    ];
  };

  const modelOptions = stationDeviceConfigsData.map(deviceConfig => {
    return {
      label: deviceConfig.model,
      value: deviceConfig.model,
    };
  });

  return (
    <Modal
      title={stationDeviceId ? `Edit Device (${stationDeviceId.substr(0, 8)})` : 'Add Device'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={stationDeviceFlatten}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Model"
            name="model"
            rules={[
              {
                required: true,
                message: 'Please select the model',
              },
            ]}
          >
            <Select
              disabled={!!stationDeviceId}
              value={selectedModel}
              onChange={(value) => setSelectedModel(value)}
            >
              {modelOptions.map(option => (
                <Option value={option.value} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Enabled"
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {selectedModel && renderSettings()}

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {stationDeviceId ? 'Update' : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};