import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Button, Switch, Select, Spin, message, Divider, Typography } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import StationZoneAutomationEditModalDailyScheduleInput from './StationZoneAutomationEditModalDailyScheduleInput';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Option } = Select;
const { Text } = Typography;

export default function StationZoneAutomationEditModal(props) {
  const {
    zoneIos,
    stationId,
    zoneId,
    zoneAutomationId,
    zoneAutomation = {
      isEnabled: false,
    },
    visible,
    onUpdate,
    onOk,
    onCancel,
  } = props;
  const zoneAutomationConfigsData = useSelector(state => state.zoneAutomationConfigs);

  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState(zoneAutomation ? zoneAutomation.type : null);
  const [zoneAutomationSettingsHasChanged, setzoneAutomationSettingsHasChanged] = useState({});
  const [allSettings, setAllSettings] = useState(zoneAutomation ? zoneAutomation.settings : {});

  // flatten settings
  const zoneAutomationFlatten = (() => {
    const flatten = {
      ...zoneAutomation,
    };

    if (zoneAutomation) {
      delete flatten.settings;

      for (let key in zoneAutomation.settings) {
        flatten[`settings.${key}`] = zoneAutomation.settings[key];
      }
    }

    return flatten;
  })();

  const onFinish = async (values) => {
    setLoading(true);

    const settingConfigs = zoneAutomationConfigsData.find(automationConfig => automationConfig.type === selectedType).settings;

    const zoneAutomationObj = {
      stationId,
      zoneId,
      name: values.name,
      type: values.type,
      isEnabled: values.isEnabled || false,
    };

    const zoneAutomationSettingsObj = {};

    // automation settings
    for (let settingConfig of settingConfigs) {
      if (zoneAutomationSettingsHasChanged[`settings.${settingConfig.key}`]) {
        let value = values[`settings.${settingConfig.key}`];

        switch (settingConfig.type) {
          case 'string':
          case 'option': {
            value = value !== undefined ? value : null;
            break;
          }
          case 'number':
            value = isNaN(parseFloat(value)) ? null : parseFloat(value);
            break;
        }

        zoneAutomationSettingsObj[settingConfig.key] = value;
      }
    }

    // edit
    if (zoneAutomationId) {
      onUpdate({
        name: 'ZONE_AUTOMATION',
        action: 'UPDATE',
        id: zoneAutomationId,
        data: zoneAutomationObj,
      });

      for (let key in zoneAutomationSettingsObj) {
        onUpdate({
          name: 'ZONE_AUTOMATION_SETTING',
          action: 'UPDATE',
          id: zoneAutomationId,
          key,
          value: zoneAutomationSettingsObj[key],
        });
      }
    } else {
      const tempId = `#${Math.random().toString().slice(2)}`;

      onUpdate({
        name: 'ZONE_AUTOMATION',
        action: 'CREATE',
        id: tempId,
        data: zoneAutomationObj,
      });

      for (let key in zoneAutomationSettingsObj) {
        onUpdate({
          name: 'ZONE_AUTOMATION_SETTING',
          action: 'UPDATE',
          id: tempId,
          key,
          value: zoneAutomationSettingsObj[key],
        });
      }
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields) {
      setzoneAutomationSettingsHasChanged({
        ...zoneAutomationSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }

    const allSettingsNew = {};
    for (let field of allFields) {
      if (field.name[0].match(/^settings\./)) {
        allSettingsNew[field.name[0].substr(9)] = field.value;
      }
    }
    setAllSettings(allSettingsNew);
  };

  const renderSettings = () => {
    const settingConfigs = zoneAutomationConfigsData.find(automationConfig => automationConfig.type === selectedType).settings.filter(setting => {
      if (setting.condition) {
        const settings = {...allSettings};
        return !!eval(setting.condition);
      }
      return true;
    });

    return [
      settingConfigs.length ? <Divider key="divider" /> : null,
      ...settingConfigs.map((item, i) => {
        switch (item.type) {
          case 'number': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
              >
                <Input
                  type="number"
                  className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'string': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
              >
                {item.key === 'runDailyScheduleHourMinute' ? (
                  <StationZoneAutomationEditModalDailyScheduleInput
                    className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  />
                ) : (
                  <Input
                    className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  />
                )}
              </Form.Item>
            );
          }
          case 'boolean': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
                valuePropName="checked"
              >
                <Switch
                  className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'option': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
              >
                <Select
                  className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  placeholder="Not selected"
                >
                  {item.options.map((option) => {
                    return (
                      <Option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }
          case 'ioId': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
              >
                <Select
                  className={zoneAutomationSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  placeholder="Not selected"
                  allowClear
                >
                  {zoneIos.map((option) => {
                    return (
                      <Option
                        key={option.id}
                        value={option.id}
                      >
                        {option.name}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }
          default:
            return (
              <span>(${item.key})</span>
            );
        }
      }),
    ];
  };

  const typeOptions = zoneAutomationConfigsData.map(automationConfig => {
    return {
      label: (
        <>
          <div>{automationConfig.title}</div>
          <Text type="secondary">{automationConfig.description}</Text>
        </>
      ),
      value: automationConfig.type,
    };
  });

  return (
    <Modal
      title={zoneAutomationId ? `Edit Automation (${zoneAutomationId.substr(0, 8)})` : 'Add Automation'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={zoneAutomationFlatten}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Please select the type',
              },
            ]}
          >
            <Select
              disabled={!!zoneAutomationId}
              value={selectedType}
              onChange={(value) => setSelectedType(value)}
            >
              {typeOptions.map(option => (
                <Option value={option.value} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Enabled"
            name="isEnabled"
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>

          {selectedType && renderSettings()}

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {zoneAutomationId ? 'Update' : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};