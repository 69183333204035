import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Tabs, Menu, Dropdown, Button, Modal } from 'antd';
import { EditOutlined, PlusOutlined, CloseOutlined, EllipsisOutlined, CopyOutlined } from '@ant-design/icons';

import SpinFull from '../../../components/SpinFull';
import SuperTable from '../../../components/SuperTable';
import StationZoneEditModal from './StationZoneEditModal';
import StationZoneCloneModal from './StationZoneCloneModal';

const { TabPane } = Tabs;

export default function StationZoneTab(props) {
  const history = useHistory();
  const {
    station,
    zones,
    onUpdate,
  } = props;

  const [tableKey, setTableKey] = useState(Math.random().toString());
  const [editModal, setEditModal] = useState({
    key: Math.random().toString(),
    visible: false,
    zoneId: null,
  });

  const [cloneModal, setCloneModal] = useState({
    key: Math.random().toString(),
    visible: false,
    zoneId: null,
  });

  const columns = [
    {
      template: 'action',
      key: 'action',
      width: 80,
      render: (value, record) => {
        return (
          <Dropdown
            overlay={
              <Menu onClick={value => {
                switch (value.key) {
                  case 'edit': {
                    setEditModal({
                      ...editModal,
                      key: Math.random().toString(),
                      visible: true,
                      zoneId: record.id,
                    });
                    break;
                  }
                  case 'clone': {
                    setCloneModal({
                      ...cloneModal,
                      key: Math.random().toString(),
                      visible: true,
                      zoneId: record.id,
                    });
                    break;
                  }
                  case 'remove': {
                    Modal.confirm({
                      title: 'Confirm to REMOVE zone',
                      content: 'Are you sure to remove this zone?',
                      onOk: () => {
                        onUpdate({
                          name: 'ZONE',
                          action: 'REMOVE',
                          id: record.id,
                        });
                        setTableKey(Math.random().toString());
                      },
                    });
                    break;
                  }
                }
              }}>
                <Menu.Item key="edit">
                  <EditOutlined />
                  Edit
                </Menu.Item>
                <Menu.Item key="clone">
                  <CopyOutlined />
                  Clone
                </Menu.Item>
                <Menu.Item key="remove">
                  <CloseOutlined />
                  Remove
                </Menu.Item>
              </Menu>
            }
          >
            <Button
              type="default"
              shape="round"
            >
              <EllipsisOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      template: 'id',
      key: 'id',
      width: 40,
      title: 'ID',
    },
    {
      key: 'name',
      title: 'Name',
    },
    {
      template: 'isEnabled',
      key: 'isEnabled',
      title: 'Enabled',
    },
  ];

  const loadDataSource = async (body) => {
    return {
      data: zones.slice(body.from, body.from + body.count),
      totalCount: zones.length,
    };
  };

  return (
    <TabPane {...props}>
      {station && zones ? (
        <>
          <div className="top-nav">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setEditModal({
                  ...editModal,
                  key: Math.random().toString(),
                  visible: true,
                  zoneId: null,
                });
              }}
            >
              Add Zone
            </Button>
          </div>

          <SuperTable
            key={tableKey}
            loadDataSource={loadDataSource}
            columns={columns}
          />

          <StationZoneEditModal
            key={editModal.key}
            stationId={station.id}
            zoneId={editModal.zoneId}
            zone={editModal.zoneId ? zones.find(zoneItem => zoneItem.id === editModal.zoneId) : null}
            visible={!!editModal.visible}
            onUpdate={onUpdate}
            onOk={() => {
              setEditModal({
                ...editModal,
                visible: false,
              });
              setTableKey(Math.random().toString());
            }}
            onCancel={() => {
              setEditModal({
                ...editModal,
                visible: false,
              });
            }}
          />

          <StationZoneCloneModal
            key={cloneModal.key}
            stationId={station.id}
            zoneId={cloneModal.zoneId}
            zone={cloneModal.zoneId ? zones.find(zoneItem => zoneItem.id === cloneModal.zoneId) : null}
            visible={!!cloneModal.visible}
            onUpdate={onUpdate}
            onOk={() => {
              setCloneModal({
                ...cloneModal,
                visible: false,
              });
              setTableKey(Math.random().toString());
            }}
            onCancel={() => {
              setCloneModal({
                ...cloneModal,
                visible: false,
              });
            }}
          />
        </>
      ) : <SpinFull />}
    </TabPane>
  );
};