import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function FarmSiteEditScreen(props) {
  const history = useHistory();
  const { siteId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [site, setSite] = useState(null);
  const [company, setCompany] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${siteId}`);
      setSite({
        ...siteRes,
        companyId: [siteRes.companyId],
      });

      const companyRes = await ToolRequest.request('GET', `/v1/company/${siteRes.companyId}`);
      setCompany(companyRes);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('PUT', `/v1/farm-site/${siteId}`, {
        name: values.name,
        address: values.address,
      });

      message.success('Update Site successfully');

      history.push('/farm-site');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title={`Edit Site (${site.name})`}
      onBack={() => history.push(history.location.pathname.replace(/^\/farm-site\/edit/, '/farm-site/dashboard'))}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={site}
          onFinish={onFinish}
        >
          <Form.Item
            label="Company"
            name="companyId"
          >
            <Cascader
              options={[
                {
                  value: company.id,
                  label: `${company.displayName} (${company.name})`,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Address"
            name="address"
          >
            <Input.TextArea
              placeholder="Address"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
