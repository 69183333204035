import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Button, Switch, Select, Spin, message, Divider } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Option } = Select;

export default function StationZoneIoEditModal(props) {
  const {
    stationId,
    zones,
    zoneId,
    zoneIoId,
    zoneIo = {},
    visible,
    onUpdate,
    onOk,
    onCancel,
  } = props;
  const zoneIoConfigsData = useSelector(state => state.zoneIoConfigs);

  const [loading, setLoading] = useState(false);

  const [selectedType, setSelectedType] = useState(zoneIo ? zoneIo.type : null);
  const [zoneIoSettingsHasChanged, setZoneIoSettingsHasChanged] = useState({});

  // flatten settings
  const zoneIoFlatten = (() => {
    const flatten = {
      ...zoneIo,
    };

    if (zoneIo) {
      delete flatten.settings;

      for (let key in zoneIo.settings) {
        flatten[`settings.${key}`] = zoneIo.settings[key];
      }
    }

    return flatten;
  })();

  const onFinish = async (values) => {
    setLoading(true);

    const settingConfigs = zoneIoConfigsData.find(zoneIoConfig => zoneIoConfig.type === selectedType).settings;

    const zoneIoObj = {
      stationId,
      zoneId,
      name: values.name,
      type: values.type,
    };

    const zoneIoSettingsObj = {};

    // io settings
    for (let settingConfig of settingConfigs) {
      if (zoneIoSettingsHasChanged[`settings.${settingConfig.key}`]) {
        let value = values[`settings.${settingConfig.key}`];

        switch (settingConfig.type) {
          case 'string':
          case 'option': {
            value = value !== undefined ? value : null;
            break;
          }
          case 'number':
            value = isNaN(parseFloat(value)) ? null : parseFloat(value);
            break;
        }

        zoneIoSettingsObj[settingConfig.key] = value;
      }
    }

    // edit
    if (zoneIoId) {
      onUpdate({
        name: 'ZONE_IO',
        action: 'UPDATE',
        id: zoneIoId,
        data: zoneIoObj,
      });

      for (let key in zoneIoSettingsObj) {
        onUpdate({
          name: 'ZONE_IO_SETTING',
          action: 'UPDATE',
          id: zoneIoId,
          key,
          value: zoneIoSettingsObj[key],
        });
      }
    } else {
      const tempId = `#${Math.random().toString().slice(2)}`;

      onUpdate({
        name: 'ZONE_IO',
        action: 'CREATE',
        id: tempId,
        data: zoneIoObj,
      });

      for (let key in zoneIoSettingsObj) {
        onUpdate({
          name: 'ZONE_IO_SETTING',
          action: 'UPDATE',
          id: tempId,
          key,
          value: zoneIoSettingsObj[key],
        });
      }
    }

    onOk();
  };

  const onFieldsChange = (changedFields, allFields) => {
    if (changedFields) {
      setZoneIoSettingsHasChanged({
        ...zoneIoSettingsHasChanged,
        [changedFields[0].name[0]]: true,
      });
    }
  };

  const renderSettings = () => {
    const settingConfigs = zoneIoConfigsData.find(zoneIoConfig => zoneIoConfig.type === selectedType).settings;

    return [
      settingConfigs.length ? <Divider key="divider" /> : null,
      ...settingConfigs.map((item, i) => {
        switch (item.type) {
          case 'number': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
                initialValue={item.defaultValue}
              >
                <Input
                  type="number"
                  className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'string': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please enter ${item.title}`,
                  }] : []),
                ]}
                initialValue={item.defaultValue}
              >
                <Input
                  className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'boolean': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
                valuePropName="checked"
                initialValue={item.defaultValue}
              >
                <Switch
                  className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                />
              </Form.Item>
            );
          }
          case 'option': {
            return (
              <Form.Item
                {...formLayout}
                key={item.key}
                label={item.title}
                name={`settings.${item.key}`}
                rules={[
                  ...(item.isRequired ? [{
                    required: true,
                    message: `Please select ${item.title}`,
                  }] : []),
                ]}
                initialValue={item.defaultValue}
              >
                <Select
                  className={zoneIoSettingsHasChanged[`settings.${item.key}`] && 'input-has-changed'}
                  placeholder="Not selected"
                >
                  {item.options.map((option) => {
                    return (
                      <Option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            );
          }
          default:
            return (
              <span>(${item.key})</span>
            );
        }
      }),
    ];
  };

  const typeOptions = zoneIoConfigsData.map(zoneIoConfig => {
    return {
      label: zoneIoConfig.type,
      value: zoneIoConfig.type,
    };
  });

  return (
    <Modal
      title={zoneIoId ? `Edit Zone IO (${zoneIoId.substr(0, 8)})` : 'Add Zone IO'}
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      width="80%"
      maskClosable={false}
      footer={null}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={zoneIoFlatten}
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
              ({getFieldValue}) => ({
                validator(_, value) {
                  const zone = zones.find(zone => zone.id === zoneId);
                  if (zone) {
                    const isExisting = zone.ios.some(io => io.name === value && io.id !== zoneIoId);

                    if (isExisting) {
                      return Promise.reject('Duplicate IO name in the same zone');
                    }
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              placeholder="Name"
            />
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Please select the type',
              },
            ]}
          >
            <Select
              disabled={!!zoneIoId}
              value={selectedType}
              onChange={(value) => setSelectedType(value)}
            >
              {typeOptions.map(option => (
                <Option value={option.value} key={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          {selectedType && renderSettings()}

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              {zoneIoId ? 'Update' : 'Submit'}
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};