import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Modal, Form, Input, Spin, Button, Checkbox } from 'antd';

const { TextArea } = Input;

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

export default function StationSettingImportModal(props) {
  const {
    visible,
    onCancel,
    station,
    stationDevices,
    zones,
    onUpdate,
    onOk,
  } = props;

  const stationSettingConfigsData = useSelector(state => state.stationSettingConfigs);

  const [loading, setLoading] = useState(false);
  const [inputData, setInputData] = useState('');

  const data = (() => {
    try {
      return JSON.parse(inputData);
    } catch (e) {
      return null;
    }
  })();

  const onFinish = async (values) => {

    if (values.selectedStationDevices && data.stationDevices) {
      for (let stationDevice of data.stationDevices) {
        const stationDeviceObj = {
          stationId: station.id,
          name: stationDevice.name,
          model: stationDevice.model,
          isEnabled: stationDevice.isEnabled,
        };

        const tempDeviceId = `#${stationDevice.id}`;

        onUpdate({
          name: 'DEVICE',
          action: 'CREATE',
          id: tempDeviceId,
          data: stationDeviceObj,
        });

        for (let key in stationDevice.settings) {
          onUpdate({
            name: 'DEVICE_SETTING',
            action: 'UPDATE',
            id: tempDeviceId,
            key,
            value: stationDevice.settings[key],
          });
        }
      }
    }

    if (values.selectedStationSettings && data.stationSettings) {
      const keys = Object.keys(data.stationSettings).filter(key => key !== 'stationName');
      for (let key of keys) {
        let value = data.stationSettings[key];
        if (key.match(/Id$/) && value !== null) {
          value = `#${value}`;
        }

        onUpdate({
          name: 'STATION_SETTING',
          action: 'UPDATE',
          key,
          value,
        });
      }
    }

    if (values.selectedZones) {
      for (let zone of data.zones) {
        const zoneObj = {
          name: zone.name,
          isEnabled: zone.isEnabled,
        };

        const tempZoneId = `#${zone.id}`;

        onUpdate({
          name: 'ZONE',
          action: 'CREATE',
          id: tempZoneId,
          data: zoneObj,
        });

        for (let zoneIo of zone.ios) {
          const zoneIoObj = {
            stationId: station.id,
            zoneId: tempZoneId,
            name: zoneIo.name,
            type: zoneIo.type,
          };

          const tempZoneIoId = `#${zoneIo.id}`;

          onUpdate({
            name: 'ZONE_IO',
            action: 'CREATE',
            id: tempZoneIoId,
            data: zoneIoObj,
          });

          // zone io setting
          for (let key in zoneIo.settings) {
            onUpdate({
              name: 'ZONE_IO_SETTING',
              action: 'UPDATE',
              id: tempZoneIoId,
              key,
              value: zoneIo.settings[key],
            });
          }

          // zone device io
          for (let zoneIoDevice of zoneIo.ioDevices) {
            const tempZoneIoDeviceId = `#${zoneIoDevice.id}`;

            onUpdate({
              name: 'ZONE_IO_DEVICE',
              action: 'CREATE',
              id: tempZoneIoDeviceId,
              data: {
                zoneIoId: tempZoneIoId,
                deviceId: `#${zoneIoDevice.deviceId}`,
                index: zoneIoDevice.index,
              },
            });

            for (let key in zoneIoDevice.settings) {
              onUpdate({
                name: 'ZONE_IO_DEVICE_SETTING',
                action: 'UPDATE',
                id: tempZoneIoDeviceId,
                key,
                value: zoneIoDevice.settings[key],
                data: {
                  zoneIoId: tempZoneIoId,
                },
              });
            }
          }
        }

        // zone automation
        for (let automation of zone.automations) {
          const zoneAutomationObj = {
            stationId: station.id,
            zoneId: tempZoneId,
            name: automation.name,
            type: automation.type,
            isEnabled: automation.isEnabled,
          };

          const tempZoneAutomationId = `#${automation.id}`;

          onUpdate({
            name: 'ZONE_AUTOMATION',
            action: 'CREATE',
            id: tempZoneAutomationId,
            data: zoneAutomationObj,
          });

          for (let key in automation.settings) {
            let value = automation.settings[key];
            if (key.match(/Id$/) && value !== null) {
              value = `#${value}`;
            }

            onUpdate({
              name: 'ZONE_AUTOMATION_SETTING',
              action: 'UPDATE',
              id: tempZoneAutomationId,
              key,
              value,
            });
          }
        }
      }
    }

    onOk();
    onCancel();
  };

  return (
    <Modal
      title="Import Settings"
      visible={visible}
      onCancel={onCancel}
      width="80%"
      footer={null}
    >
      <Form
        {...formLayout}
        initialValues={{
          selectedStationSettings: true,
          selectedStationDevices: true,
          selectedZones: true,
        }}
        onFinish={onFinish}
      >
        <Form.Item
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            rows={2}
            value={inputData}
            onChange={(e) => setInputData(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          {...formLayout}
          label="Settings"
        >
          <Form.Item
            name="selectedStationSettings"
            valuePropName="checked"
          >
            <Checkbox defaultChecked>Station Settings</Checkbox>
          </Form.Item>
          <Form.Item
            name="selectedStationDevices"
            valuePropName="checked"
          >
            <Checkbox defaultChecked>Devices</Checkbox>
          </Form.Item>
          <Form.Item
            name="selectedZones"
            valuePropName="checked"
          >
            <Checkbox defaultChecked>Zones</Checkbox>
          </Form.Item>
        </Form.Item>

        <Spin spinning={loading}>
          <Form.Item {...formTailLayout}>
            <Button
              type="primary"
              htmlType="submit"
              block
              disabled={!data}
            >
              Submit
            </Button>
          </Form.Item>
        </Spin>
      </Form>
    </Modal>
  );
};