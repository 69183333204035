import React, { useState, useEffect } from 'react';
import ReactECharts from 'echarts-for-react';
import moment from 'moment';

import { DatePicker, Card, Row, Col, Badge, Tabs, Select, Space, Divider } from 'antd';
import * as ToolRequest from '../../../tools/ToolRequest';

import StationDashboardGraphInputChart from './StationDashboardGraphInputChart';
import StationDashboardGraphOutputChart from './StationDashboardGraphOutputChart';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const { Option } = Select;

export default function StationDashboardGraphTab(props) {
  const {
    station,
    zones,
  } = props;

  const [chartComponentKey, setChartComponentKey] = useState(Math.random().toString());

  const [chartSelectedRange, setChartSelectedRange] = useState(4);
  const endAtToday = moment().startOf('minute');
  const beginAtToday = moment(endAtToday).subtract(4, 'hour');

  const [chartSelectedDates, setChartSelectedDates] = useState([beginAtToday, endAtToday]);

  return (
    <TabPane {...props}>
      <div id="chartContainer">
        <div className="top-nav">
          <Space>
            <span>Range:</span>
            {!chartSelectedRange && (
              <RangePicker
                showTime={true}
                allowClear={false}
                value={chartSelectedDates}
                onChange={(dates) => {
                  setChartSelectedDates(dates);
                  setChartComponentKey(Math.random().toString());
                }}
              />
            )}
            <Select
              value={chartSelectedRange}
              onChange={(value) => {
                setChartSelectedRange(value);
                const now = moment().startOf('minute');
                if (value) {
                  setChartSelectedDates([moment(now).subtract(value, 'hour'), now]);
                }
                setChartComponentKey(Math.random().toString());
              }}
              dropdownMatchSelectWidth={150}
            >
              <Option value={1}>Past 1 hour</Option>
              <Option value={4}>Past 4 hours</Option>
              <Option value={8}>Past 8 hours</Option>
              <Option value={12}>Past 12 hours</Option>
              <Option value={24}>Past 24 hours</Option>
              <Option value={48}>Past 2 days</Option>
              <Option value={168}>Past 7 days</Option>
              <Option value={null}>Custom</Option>
            </Select>
          </Space>
        </div>

        {zones.map(zone => (
          <Card
            key={zone.id}
            className="card-container"
            style={{marginTop: 16}}
            title={zone.name}
          >
            <Divider>Sensors</Divider>
            <Row gutter={8}>
              {zone.ios.filter(zoneIo => zoneIo.config.inputs).map(zoneIo => {
                let suffix;
                let min = zoneIo.settings.min;
                let max = zoneIo.settings.max;
                let interval;
                let formatter = v => v;
                let labelFormatter = v => v;
                let showMarker = true;
                let color = '#2C5BFF';

                switch (zoneIo.type) {
                  case 'AIR_HUMIDITY': {
                    suffix = '%';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    labelFormatter = value => value.toFixed(0);
                    color = '#32B700';
                    break;
                  }
                  case 'AIR_TEMPERATURE': {
                    suffix = '°C';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    labelFormatter = value => value.toFixed(0);
                    color = '#32B700';
                    break;
                  }
                  case 'CO2_PPM': {
                    suffix = 'ppm';
                    formatter = value => value === null ? '-' : value.toFixed(0);
                    labelFormatter = value => value.toFixed(0);
                    color = '#32B700';
                    break;
                  }
                  case 'EC_VALUE': {
                    suffix = 'us/cm';
                    formatter = value => value === null ? '-' : value.toFixed(0);
                    labelFormatter = value => value.toFixed(0);
                    break;
                  }
                  case 'PH_VALUE': {
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    labelFormatter = value => value.toFixed(0);
                    break;
                  }
                  case 'WATER_LEVEL':
                  case 'WATER_LEVEL_DIGITAL': {
                    min = 0;
                    max = 2;
                    interval = 1;
                    showMarker = false;
                    formatter = value => ['Low', 'Normal', 'Full'][value] || '-';
                    labelFormatter = value => ['Low', 'Normal', 'Full'][value];
                    break;
                  }
                  case 'WATER_TEMPERATURE': {
                    suffix = '°C';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    labelFormatter = value => value.toFixed(0);
                    break;
                  }
                  case 'DISSOLVED_OXYGEN': {
                    suffix = 'mg/L';
                    formatter = value => value === null ? '-' : value.toFixed(2);
                    labelFormatter = value => value.toFixed(0);
                    break;
                  }
                  case 'OXYGEN_SATURATION': {
                    suffix = '%';
                    formatter = value => value === null ? '-' : value.toFixed(1);
                    labelFormatter = value => value.toFixed(0);
                    break;
                  }
                  case 'OXYGEN_PARTIAL_PRESSURE': {
                    suffix = 'hPa';
                    formatter = value => value === null ? '-' : value.toFixed(0);
                    labelFormatter = value => value.toFixed(0);
                    break;
                  }
                  case 'CAMERA': {
                    return;
                  }
                }

                return (
                  <Col key={zoneIo.id} xs={24} sm={12}>
                    <Card>
                      <StationDashboardGraphInputChart
                        style={{height: 200}}
                        key={chartComponentKey}
                        zoneId={zone.id}
                        ioName={zoneIo.name}
                        settings={zoneIo.settings}
                        suffix={suffix}
                        min={min}
                        max={max}
                        interval={interval}
                        showMarker={showMarker}
                        color={color}
                        formatter={formatter}
                        labelFormatter={labelFormatter}
                        beginAt={chartSelectedDates[0]}
                        endAt={chartSelectedDates[1]}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>

            <Divider>Controls</Divider>
            <Row gutter={8} style={{ marginTop: 16 }}>
              {zone.ios.filter(zoneIo => zoneIo.config.outputs).map(zoneIo => {
                let color = '#2C5BFF';

                switch (zoneIo.type) {
                  case 'LED': {
                    color = '#F0BA00';
                    break;
                  }
                  case 'MAIN_WATER_PUMP': {
                    color = '#0045E0';
                    break;
                  }
                  case 'NUTRITION_PUMP': {
                    color = '#00E00B';
                    break;
                  }
                }

                return (
                  <Col key={zoneIo.id} xs={24} sm={12}>
                    <Card>
                      <StationDashboardGraphOutputChart
                        key={chartComponentKey}
                        zoneId={zone.id}
                        ioName={zoneIo.name}
                        color={color}
                        beginAt={chartSelectedDates[0]}
                        endAt={chartSelectedDates[1]}
                        style={{height: 150}}
                      />
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Card>
        ))}
      </div>
    </TabPane>
  );
};