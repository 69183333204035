import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Tabs, Calendar, Row, Col, Card, Badge, Image, Tooltip, Button, Empty } from 'antd';
import { CameraFilled, LeftOutlined, RightOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../../tools/ToolRequest';

import SpinFull from '../../../components/SpinFull';

const { TabPane } = Tabs;

export default function StationDashboardCameraTab(props) {
  const {
    station,
    zones,
  } = props;

  const [selectedDate, setSelectedDate] = useState(moment());

  const [cameraFileCountsByTime, setCameraFileCountByTimes] = useState({});

  const [loadingCameraFiles, setLoadingCameraFiles] = useState(true);
  const [selectedCameraFilesByTime, setSelectedCameraFilesByTime] = useState([]);

  const [selectedCameraFileKey, setSelectedCameraFileKey] = useState(null);
  const [selectedCameraFileIndexKey, setSelectedCameraFileIndexKey] = useState(0);

  const onSelectCalendar = (value) => {
    setSelectedDate(value);

    const beginAt = moment(value).startOf('day');
    const endAt = moment(beginAt).add(1, 'day');

    setLoadingCameraFiles(true);

    (async () => {
      const cameraFilesByTimeTmp = {};

      for (let zone of zones) {
        for (let zoneIo of zone.ios) {
          if (zoneIo.config.inputs && zoneIo.config.inputs.indexOf('IMAGE_INPUT') > -1) {

            const fileRes = await ToolRequest.request('GET', '/v1/station-file', {
              stationId: station.id,
              zoneIoId: zoneIo.id,
              beginAt: beginAt.toISOString(),
              endAt: endAt.toISOString(),
              ascending: true,
              count: 1000,
            });

            for (let file of fileRes.data) {
              const date = moment(file.capturedAt).format('HH:mm');

              if (!cameraFilesByTimeTmp[date]) {
                cameraFilesByTimeTmp[date] = [];
              }
              cameraFilesByTimeTmp[date].push({
                file,
                zoneIo,
              });
            }
          }
        }
      }

      const cameraFilesByTime = [];
      for (let date in cameraFilesByTimeTmp) {
        cameraFilesByTime.push({
          date,
          files: cameraFilesByTimeTmp[date],
        });
      }

      setSelectedCameraFilesByTime(cameraFilesByTime);
      setSelectedCameraFileKey('0');
      setLoadingCameraFiles(false);
    })();
  };

  const onPanelChangeCalendar = (value) => {
    const beginAt = moment(value).startOf('month');
    beginAt.subtract(beginAt.day(), 'day');

    const endAt = moment(beginAt).add(42, 'day');

    (async () => {
      const zoneIoIds = [];
      for (let zone of zones) {
        for (let zoneIo of zone.ios) {
          if (zoneIo.config.inputs && zoneIo.config.inputs.indexOf('IMAGE_INPUT') > -1) {
            zoneIoIds.push(zoneIo.id);
          }
        }
      }

      const cameraFilesNew = {};

      for (let zoneIoId of zoneIoIds) {
        const res = await ToolRequest.request('GET', '/v1/station-file-date', {
          stationId: station.id,
          zoneIoId,
          beginAt: beginAt.toISOString(),
          endAt: endAt.toISOString(),
        });

        for (let tmp of res.data) {
          cameraFilesNew[tmp.date] = tmp;
        }
      }
      setCameraFileCountByTimes(cameraFilesNew);
    })();
  }

  const onChangeCameraFileTime = (activeKeyNew) => {
    setSelectedCameraFileKey(activeKeyNew);

    if (selectedCameraFilesByTime[activeKeyNew] && selectedCameraFileIndexKey > selectedCameraFilesByTime[activeKeyNew].files.length - 1) {
      setSelectedCameraFileIndexKey(0);
    }
  }

  useEffect(() => {
    const now = moment();
    onSelectCalendar(now);
    onPanelChangeCalendar(now);
  }, []);

  const dateCellRender = (value) => {
    return cameraFileCountsByTime[value.format('YYYY-MM-DD')] ? (
      <div className="camera-calendar-cell-display">
        <CameraFilled />
      </div>
    ) : null;
  };

  return (
    <TabPane {...props}>
      <Row gutter={8}>
        <Col md={24} lg={12}>
          <Card id="cameraCalendarContainer">
            <Calendar
              value={selectedDate}
              onSelect={onSelectCalendar}
              onPanelChange={onPanelChangeCalendar}
              dateCellRender={dateCellRender}
            />
          </Card>
        </Col>
        <Col md={24} lg={12}>
          {loadingCameraFiles ? <SpinFull /> : (
            <Card id="cameraCalendarFileContainer">
              {selectedCameraFilesByTime.length ? (
                <Tabs
                  tabPosition="top"
                  defaultActiveKey="0"
                  activeKey={String(selectedCameraFileKey)}
                  onChange={activeKey => onChangeCameraFileTime(activeKey)}
                  tabBarExtraContent={{
                    left: (
                      <Button
                        type="primary"
                        size="small"
                        disabled={selectedCameraFileKey <= 0}
                        onClick={() => onChangeCameraFileTime((Number(selectedCameraFileKey) - 1).toString())}
                      >
                        <LeftOutlined />
                      </Button>
                    ),
                    right: (
                      <Button
                        type="primary"
                        size="small"
                        disabled={selectedCameraFileKey >= selectedCameraFilesByTime.length - 1}
                        onClick={() => onChangeCameraFileTime((Number(selectedCameraFileKey) + 1).toString())}
                      >
                        <RightOutlined />
                      </Button>
                    ),
                  }}
                >
                  {selectedCameraFilesByTime.map((cameraFilesByTime, i) => {
                    return (
                      <TabPane tab={`${cameraFilesByTime.date}`} key={i}>
                        <Tabs
                          type="card"
                          defaultActiveKey="0"
                          activeKey={String(selectedCameraFileIndexKey)}
                          onChange={activeKey => setSelectedCameraFileIndexKey(activeKey)}
                        >
                          {cameraFilesByTime.files.map((cameraFile, j) => {
                            return (
                              <TabPane tab={cameraFile.zoneIo.name} key={j}>
                                <Image src={cameraFile.file.url} />
                                <div>
                                  <Tooltip title={moment(cameraFile.file.capturedAt).format('YYYY-MM-DD HH:mm:ss')}>
                                    Captured {moment(cameraFile.file.capturedAt).fromNow()}
                                  </Tooltip>
                                </div>
                              </TabPane>
                            );
                          })}
                        </Tabs>
                      </TabPane>
                    );
                  })}
                </Tabs>
              ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No images" />}
            </Card>
          )}
        </Col>
      </Row>
    </TabPane>
  );
};