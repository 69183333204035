import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';
import * as ToolPermission from '../../tools/ToolPermission';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function FarmPlantingRecordEditScreen(props) {
  const history = useHistory();
  const { plantingRecordId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plantingRecord, setPlantingRecord] = useState(null);
  const [site, setSite] = useState(null);
  const [seedlingTray, setSeedlingTray] = useState(null);
  const [plantingTray, setPlantingTray] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const plantingRecordRes = await ToolRequest.request('GET', `/v1/farm-planting-record/${plantingRecordId}`);
      setPlantingRecord({
        ...plantingRecordRes,
        siteId: [plantingRecordRes.siteId],
        seedlingTrayId: [plantingRecordRes.seedlingTrayId],
        plantingTrayId: [plantingRecordRes.plantingTrayId],
      });

      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${plantingRecordRes.siteId}`);
      setSite(siteRes);

      const seedlingTrayRes = await ToolRequest.request('GET', `/v1/farm-seedling-tray/${plantingRecordRes.seedlingTrayId}`);
      setSeedlingTray(seedlingTrayRes);

      const plantingTrayRes = await ToolRequest.request('GET', `/v1/farm-planting-tray/${plantingRecordRes.plantingTrayId}`);
      setPlantingTray(plantingTrayRes);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('PUT', `/v1/farm-planting-record/${plantingRecordId}`, {
        amount: values.amount,
      });

      message.success('Update Planting Record successfully');

      history.push('/farm-planting-record');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title={`Edit Planting Record (${plantingRecordId})`}
      onBack={() => history.push('/farm-planting-record')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={plantingRecord}
          onFinish={onFinish}
        >
          <Form.Item
            label="Site"
            name="siteId"
          >
            <Cascader
              options={[
                {
                  value: site.id,
                  label: site.name,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Seed Purchase"
            name="seedlingTrayId"
          >
            <Cascader
              options={[
                {
                  value: seedlingTray.id,
                  label: seedlingTray.readableId,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Planting Tray"
            name="plantingTrayId"
          >
            <Cascader
              options={[
                {
                  value: plantingTray.id,
                  label: plantingTray.readableId,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="No. of Plantings"
            name="amount"
            rules={[
              {
                required: true,
                message: 'Please enter the no. of plantings',
              },
            ]}
          >
            <InputNumber
              placeholder="1"
              min={1}
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
