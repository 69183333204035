import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { PageHeader, Tabs, Button, Select, Card, Space } from 'antd';
import { AppstoreOutlined, DashboardOutlined, BarChartOutlined, LeftOutlined, CameraOutlined, SettingOutlined, ControlOutlined, RobotOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import StationDashboardOverviewTab from './StationDashboardOverview/StationDashboardOverviewTab';
import StationDashboardInputTab from './StationDashboardInput/StationDashboardInputTab';
import StationDashboardOutputTab from './StationDashboardOutput/StationDashboardOutputTab';
import StationDashboardAutomationTab from './StationDashboardAutomation/StationDashboardAutomationTab';
import StationDashboardGraphTab from './StationDashboardGraph/StationDashboardGraphTab';
import StationDashboardCameraTab from './StationDashboardCamera/StationDashboardCameraTab';
import StationDashboardOSTab from './StationDashboardOS/StationDashboardOSTab';
import SpinFull from '../../components/SpinFull';

import '../../less/StationDashboardScreen.less';

const { Option } = Select;

export default function StationDashboardScreen(props) {
  const history = useHistory();
  const { stationId } = useParams();
  const zoneIoConfigsData = useSelector(state => state.zoneIoConfigs);

  const [loaded, setLoaded] = useState(false);
  const [station, setStation] = useState(null);
  const [zones, setZones] = useState([]);

  const [selectedZoneId, setSelectedZoneId] = useState(null);
  const [selectedZones, setSelectedZones] = useState(null);
  const [selectedZonesKey, setSelectedZonesKey] = useState(Math.random().toString());
  const [selectedZoneStatuses, setSelectedZoneStatuses] = useState(null);

  const [reloadingStationZoneStatuses, setReloadingStationZoneStatuses] = useState(false);
  const [reloadingStationZoneStatusesCountdown, setReloadingStationZoneStatusesCountdown] = useState(60);

  useEffect(() => {
    setLoaded(false);

    (async () => {
      const stationRes = await ToolRequest.request('GET', `/v1/station/${stationId}`);
      setStation(stationRes);

      // zones
      const zoneRes = await ToolRequest.request('GET', `/v1/station-zone`, {
        stationId,
        count: 1000,
      });
      setZones(zoneRes.data);

      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    if (loaded) {
      setSelectedZones(null);

      (async () => {
        const selectedZonesOriginal = selectedZoneId ? [zones.find(zone => zone.id === selectedZoneId)] : zones;

        // zones
        const selectedZonesNew = [];

        for (let zone of selectedZonesOriginal) {

          // ios
          const zoneIosRes = await ToolRequest.request('GET', `/v1/station-zone-io`, {
            zoneId: zone.id,
            count: 1000,
          });

          const ios = [];

          for (let zoneIo of zoneIosRes.data) {
            const zoneIoConfig = zoneIoConfigsData.find(zoneIoConfigItem => zoneIoConfigItem.type === zoneIo.type);

            if (!zoneIoConfig || (!zoneIoConfig.inputs && !zoneIoConfig.outputs)) {
              continue;
            }

            const zoneIoSettings = await ToolRequest.request('GET', `/v1/station-zone-io/${zoneIo.id}/setting`)

            ios.push({
              ...zoneIo,
              config: zoneIoConfig,
              settings: zoneIoSettings,
            });
          }

          // automations
          const zoneAutomationsRes = await ToolRequest.request('GET', `/v1/station-zone-automation`, {
            zoneId: zone.id,
            count: 1000,
          });

          selectedZonesNew.push({
            ...zone,
            ios,
            automations: zoneAutomationsRes.data,
          });
        }

        setSelectedZones(selectedZonesNew);
        setSelectedZonesKey(Math.random().toString());
      })();
    }
  }, [loaded, selectedZoneId]);

  const loadStationStatus = () => {
    (async () => {
      setReloadingStationZoneStatuses(true);

      // zone status
      const zoneStatusesNew = {};

      for (let zone of selectedZones) {
        const zoneStatusesRes = await ToolRequest.request('GET', `/v1/station-zone-status`, {
          zoneId: zone.id,
        });
        zoneStatusesNew[zone.id] = zoneStatusesRes;
      }

      // zone automations
      const selectedZonesNew = [];

      for (let selectedZone of selectedZones) {
        const zoneAutomationsRes = await ToolRequest.request('GET', `/v1/station-zone-automation`, {
          zoneId: selectedZone.id,
          count: 1000,
        });

        selectedZonesNew.push({
          ...selectedZone,
          automations: zoneAutomationsRes.data,
        });
      }

      setSelectedZoneStatuses(zoneStatusesNew);
      setSelectedZones(selectedZonesNew);

      setReloadingStationZoneStatusesCountdown(60);
      setReloadingStationZoneStatuses(false);
    })();
  };

  useEffect(() => {
    if (loaded && selectedZones) {
      loadStationStatus();
    }
  }, [loaded, selectedZonesKey]);

  useEffect(() => {
    let interval;
    if (loaded) {
      interval = setInterval(() => {
        if (!reloadingStationZoneStatuses) {
          setReloadingStationZoneStatusesCountdown(value => {
            if (!value) {
            } else {
              if (value === 1) {
                loadStationStatus();
              }
              return value - 1;
            }
          });
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [loaded, selectedZones, reloadingStationZoneStatuses]);

  return loaded ? (
    <PageHeader
      title={`Station Dashboard (${station ? station.displayName : '...'})`}
      onBack={() => history.push('/station')}
      extra={[
        <Button
          key="setting"
          icon={<SettingOutlined />}
          href={`#/station/setting/${stationId}`}
        >
          Settings
        </Button>
      ]}
    >
      <Card size="small" style={{ marginBottom: 8 }}>
        <Space>
          <span>Zone:</span>
          <Select
            onChange={value => setSelectedZoneId(value)}
            value={selectedZoneId}
          >
            <Option value={null}>All {zones.length} Zones</Option>
            {zones.map(zone => (
              <Option key={zone.id} value={zone.id}>{zone.name}</Option>
            ))}
          </Select>
        </Space>
      </Card>

      {selectedZones ? (
        <Tabs defaultActiveKey="overview">
          <StationDashboardOverviewTab
            key="overview"
            tab={
              <span>
                <AppstoreOutlined />
                Overview
              </span>
            }
            station={station}
            zones={selectedZones}
            zoneStatuses={selectedZoneStatuses}
            reloadingStationZoneStatuses={reloadingStationZoneStatuses}
            reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
            reloadStationZoneStatuses={() => loadStationStatus()}
          />
          <StationDashboardInputTab
            key="input"
            tab={
              <span>
                <DashboardOutlined />
                Sensors
              </span>
            }
            station={station}
            zones={selectedZones}
            zoneStatuses={selectedZoneStatuses}
            reloadingStationZoneStatuses={reloadingStationZoneStatuses}
            reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
            reloadStationZoneStatuses={() => loadStationStatus()}
          />
          <StationDashboardOutputTab
            key="output"
            tab={
              <span>
                <ControlOutlined />
                Controls
              </span>
            }
            station={station}
            zones={selectedZones}
            zoneStatuses={selectedZoneStatuses}
            reloadingStationZoneStatuses={reloadingStationZoneStatuses}
            reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
            reloadStationZoneStatuses={() => loadStationStatus()}
          />
          <StationDashboardAutomationTab
            key="automation"
            tab={
              <span>
                <RobotOutlined />
                Automations
              </span>
            }
            station={station}
            zones={selectedZones}
            reloadingStationZoneStatuses={reloadingStationZoneStatuses}
            reloadingStationZoneStatusesCountdown={reloadingStationZoneStatusesCountdown}
            reloadStationZoneStatuses={() => loadStationStatus()}
          />
          <StationDashboardGraphTab
            key="graph"
            tab={
              <span>
                <BarChartOutlined />
                Graph
              </span>
            }
            station={station}
            zones={selectedZones}
          />
          <StationDashboardCameraTab
            key="camera"
            tab={
              <span>
                <CameraOutlined />
                Camera
              </span>
            }
            station={station}
            zones={selectedZones}
          />
          <StationDashboardOSTab
            key="os"
            tab={
              <span>
                <FundProjectionScreenOutlined />
                OS
              </span>
            }
            station={station}
            zones={selectedZones}
          />
        </Tabs>
      ) : <SpinFull />}
    </PageHeader>
  ) : <SpinFull />;
};
