import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';

import { PageHeader, Form, Input, Button, Switch, Cascader, Typography, Spin, message, DatePicker, Badge, Select, InputNumber } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import * as ToolRequest from '../../tools/ToolRequest';

import SpinFull from '../../components/SpinFull';

const formLayout = {
  labelCol: {
    sm: { span: 5 },
  },
  wrapperCol: {
    sm: { span: 16 },
  },
  labelWrap: true,
};

const formTailLayout = {
  wrapperCol: {
    md: { offset: 5, span: 16 },
  },
};

const { Title } = Typography;

export default function FarmPlantingTrayEditScreen(props) {
  const history = useHistory();
  const { plantingTrayId } = useParams();

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [plantingTray, setPlantingTray] = useState(null);
  const [site, setSite] = useState(null);

  const { metadata } = props;

  useEffect(() => {
    (async () => {
      const plantingTrayRes = await ToolRequest.request('GET', `/v1/farm-planting-tray/${plantingTrayId}`);
      setPlantingTray({
        ...plantingTrayRes,
        siteId: [plantingTrayRes.siteId],
        purchasedAt: moment(plantingTrayRes.purchasedAt),
      });

      const siteRes = await ToolRequest.request('GET', `/v1/farm-site/${plantingTrayRes.siteId}`);
      setSite(siteRes);

      setLoaded(true);
    })();
  }, []);

  const onFinish = async (values) => {
    setLoading(true);

    try {
      const res = await ToolRequest.request('PUT', `/v1/farm-planting-tray/${plantingTrayId}`, {
        name: values.name,
        type: values.type,
        slotCount: values.slotCount,
        remarks: values.remarks,
        status: values.status,
      });

      message.success('Update Site successfully');

      history.push('/farm-planting-tray');
    } catch (err) {
      setLoading(false);

      if (err?.response?.data) {
        switch (err.response.data) {
          default:
            return message.error('Unknown errors. Please retry');
        }
      }

      message.error('Unknown error. Please retry');
    }
  };

  return loaded ? (
    <PageHeader
      title={`Edit Planting Tray (${plantingTray.readableId})`}
      onBack={() => history.push('/farm-planting-tray')}
    >
      <Spin spinning={loading}>
        <Form
          {...formLayout}
          initialValues={plantingTray}
          onFinish={onFinish}
        >
          <Form.Item
            label="Site"
            name="siteId"
          >
            <Cascader
              options={[
                {
                  value: site.id,
                  label: site.name,
                },
              ]}
              disabled={true}
            />
          </Form.Item>

          <Form.Item
            label="Type"
            name="type"
          >
            <Input
              placeholder="Type"
            />
          </Form.Item>

          <Form.Item
            label="Slot Amount"
            name="slotCount"
          >
            <InputNumber
              placeholder="Slot Amount"
            />
          </Form.Item>

          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: 'Please select the status',
              },
            ]}
          >
            <Select
              allowClear={false}
              options={[
                {
                  value: 'NORMAL',
                  label: <Badge status="success" text="Normal" />,
                },
                {
                  value: 'STALED',
                  label: <Badge status="error" text="Staled" />,
                },
              ]}
              // displayRender={(label, selectedOptions) => label}
            />
          </Form.Item>

          <Form.Item
            label="Remarks"
            name="remarks"
          >
            <Input.TextArea
              placeholder="Remarks"
            />
          </Form.Item>

          <Form.Item {...formTailLayout}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </PageHeader>
  ) : <SpinFull />;
};
